/**
* Sends a get request to backend
* @param {url:''} OR string url 
* @returns json
*/
import router from '@/router'

export default {
    async getRequestJson({state}, url) {
        const responce = await fetch(state.asyncUrl + url, {
            method: 'GET',
            headers: {
                'Content-Type': 'text/plain',
                Authorization: 'Bearer ' + state.JWT,
            },
            });

        const objectResponce = await responce.json();
        if (objectResponce.status == false) {
            if (objectResponce.error == 'NO_VALID_TOKEN'
                || objectResponce.error == 'EXPIRED_TOKEN'
            ) {
                localStorage.setItem('JWT', null);
                state.JWT = null;
                router.push({ name: 'login' });
                return;
            }
        }

        return objectResponce;
    },
    async getRequestBlob({state}, url) {
        const responce = await fetch(state.asyncUrl + url, {
            method: 'GET',
            headers: {
                'Content-Type': 'text/plain',
                Authorization: 'Bearer ' + state.JWT,
            },
            });

        const objectResponce = await responce.blob();
        if (objectResponce.status == false) {
            if (objectResponce.error == 'NO_VALID_TOKEN'
                || objectResponce.error == 'EXPIRED_TOKEN'
            ) {
                localStorage.setItem('JWT', null);
                state.JWT = null;
                router.push({ name: 'login' });
                return;
            }
        }

        return objectResponce;
    }
}