import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes = [
    //dashboard
    {   path: '/', 
        name: 'Home', 
        component: () => import('../views/index.vue'),
        meta: {
            requiresAuth: true
        }
    },

    {   path: '/import', 
        name: 'import', 
        component: () => import('../views/requests/import.vue'),
        meta: {
            requiresAuth: true
        }
    },
    /**
     * REQUESTS
     */
    {
        path: '/request/edit/:id',
        name: 'requestEdit',
        component: () => import( '../views/requests/edit.vue'),
        meta: {
            requiresAuth: true,
            isEdit:true
        }
    },
    {
        path: '/request/add',
        name: 'requestAdd',
        component: () => import( '../views/requests/edit.vue'),
        meta: {
            requiresAuth: true,
            isEdit:false
        }
    },
    {
        path: '/requests',
        name: 'requests',
        component: () => import('../views/requests/list.vue'),
        meta: {
            requiresAuth: true
        }
    },

    /**
     * USERS
     */
    {
        path: '/users/profile',
        name: 'profile',
        component: () => import( '../views/users/account_setting.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/users',
        name: 'users',
        component: () => import( '../views/users/user_list.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/user/edit/:id',
        name: 'usersEdit',
        component: () => import( '../views/users/user_detail.vue'),
        meta: {
            requiresAuth: true,
            isEdit:true
        }
    },
    {
        path: '/user/add',
        name: 'usersAdd',
        component: () => import( '../views/users/user_detail.vue'),
        meta: {
            requiresAuth: true,
            isEdit:false
        }
    },

    /**
     * AUTH
     */
    {
        path: '/auth/login',
        name: 'login',
        component: () => import('../views/auth/login.vue'),
        meta: { layout: 'auth', requiresAuth: false }
    }
];

const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
        store.commit('setLayout', 'auth');
    } else {
        store.commit('setLayout', 'app');
    }
    
    if (to.matched.some(record => record.meta.requiresAuth)) {
      
      // this route requires auth, check if logged in
      if (!store.state.JWT || (store.state.JWT == null)) {
        
        return next({ name: 'login' })
      } else {
        return next() 
      }
    } 

    return next();
  });

export default router;
