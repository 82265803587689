import Vue from 'vue';
import Vuex from 'vuex';
import i18n from '../i18n';
import getRequestJson from '../actions/getRequest';
import postRequestJson from '../actions/postRequest';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        layout: 'app',
        is_show_sidebar: true,
        is_show_search: false,
        is_dark_mode: false,
        dark_mode: 'light',
        locale: null,
        JWT: localStorage.getItem('JWT'),
        menu_style: 'vertical',
        layout_style: 'full',
        asyncUrl: 'https://sys.kaleiatravel.com/app/index.php?page=asr',
        //asyncUrl: 'http://localhost/clients/kaleia/site/index.php?page=asr',
        countryList: [
            { code: 'en', name: 'English' },
            { code: 'bg', name: 'Български' }
        ]
    },
    mutations: {
        setLayout(state, payload) {
            state.layout = payload;
        },
        toggleSideBar(state, value) {
            state.is_show_sidebar = value;
        },
        toggleSearch(state, value) {
            state.is_show_search = value;
        },
        toggleLocale(state, value) {
            value = value || 'en';
            i18n.locale = value;
            localStorage.setItem('i18n_locale', value);
            state.locale = value;
        },

        toggleDarkMode(state, value) {
            //light|dark|system
            value = value || 'light';
            localStorage.setItem('dark_mode', value);
            state.dark_mode = value;
            if (value == 'light') {
                state.is_dark_mode = false;
            } else if (value == 'dark') {
                state.is_dark_mode = true;
            } 

            if (state.is_dark_mode) {
                document.querySelector('body').classList.add('dark');
            } else {
                document.querySelector('body').classList.remove('dark');
            }
        },

        toggleMenuStyle(state, value) {
            //horizontal|vertical|collapsible-vertical
            value = value || '';
            localStorage.setItem('menu_style', value);
            state.menu_style = value;
            if (!value || value === 'vertical') {
                state.is_show_sidebar = true;
            } else if (value === 'collapsible-vertical') {
                state.is_show_sidebar = false;
            }
        },

        toggleLayoutStyle(state, value) {
            //boxed-layout|large-boxed-layout|full
            value = value || '';
            localStorage.setItem('layout_style', value);
            state.layout_style = value;
        },

        logout(state, router) {
            localStorage.setItem('JWT', null);
            state.JWT = null;
            router.push({ name: 'login' });
        }
    },
    getters: {
        layout(state) {
            return state.layout;
        }
    },
    actions: {
        ...getRequestJson,
        ...postRequestJson
    },
    modules: {}
});
