/**
 * Posts a request to backend
 * @param {url:'', parameters: {}} payload 
 * @returns json
 */
 import router from '@/router'
 
export default {
     async postRequestJson({state}, payload)
     { 
         const responce = await fetch(state.asyncUrl + payload.url, {
             method: 'POST',
             credentials: 'same-origin',
             headers: {
               'Content-Type': 'application/json',
               Authorization: 'Bearer ' + state.JWT,
             },
             body : JSON.stringify(payload.params)
           });
         
           const objectResponce = await responce.json();
           if (objectResponce.status == false) {
               if (objectResponce.error == 'NO_VALID_TOKEN'
                   || objectResponce.error == 'EXPIRED_TOKEN'
               ) {
                   localStorage.setItem('JWT', null);
                   state.JWT = null;
                   router.push({ name: 'login' });
               }
           }
           
           return objectResponce;
     }
}